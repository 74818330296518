import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './MainPage';
import AvorPage from './AvorPage';
import AvorInstructions from './AvorInstructions';
import CyberProPage from './CyberProPage';
import CyberProFAQ from './CyberProFAQ';
import CyberProSupport from './CyberProSupport';
import AntiVorAntiVirus from './AntiVorAntiVirus';
import CreatePage from "./CreatePage";
import './i18n';

function App() {

    return (

        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<MainPage/>}/>
                    <Route path="/AntiThief" element={<AvorPage/>} />
                    <Route path="/buy/" element={<AvorPage/>} />
                    <Route path="/instructions/" element={<AvorInstructions/>} />
                    <Route path="/CyberProtection" element={<CyberProPage/>} />
                    <Route path="/CyberProtectionFAQ" element={<CyberProFAQ/>} />
                    <Route path="/CyberProtectionSupport" element={<CyberProSupport/>} />
                    <Route path="/AntiVorAntiVirus" element={<AntiVorAntiVirus/>}/>
                    <Route path="/product" element={<CreatePage/>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;