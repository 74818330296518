import './App.css';
import Header_2 from "./Background/Header_2"
import End from "./Content/End";
import {CyberProtection, CyberProtectionPartners} from "./Content/Content";
import Design from "./Content/Design";

const headingText1 = "Cyber Protection";
const Price_1 = ["390 р/мес", "3900 р/год"];


function CPPage() {


    return (
        <div className="App">
            <header>
                <Header_2/>
            </header>

            <div id="Body">

            <div style={{paddingTop: '150px'}}>
                <CyberProtectionPartners
                    Design={Design}
                    TypeDesign={'1'}
                    headingText={headingText1}
                    Price={Price_1}
                />
            </div>

            <End />
            </div>
        </div>
    );
}

export default CPPage;
